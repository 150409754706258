.canvasContainer {
  position: absolute;
  left: 0px;
  width: 80%;
  height: 100vh;
  float: left;
}

.infoPanel {
  width: 40%;
  height: calc(100vh - 4em);
  padding-top:  2em;
  padding-bottom: 2em;
  padding-right: 2em;
  position: absolute;
  right:  0px;
}

.infoPanelGallery {
  width: 45%;
  height: calc(100vh - 4em);
  padding-right: 2em;
  position: absolute;
  right:  0px;
}

.overflowPossible {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.blackContainer {
  background-color: black;
  border-radius: 50px;
  width: 80%;
  padding: 2em;
  margin: auto;
  color:  white;
  position: absolute;
  bottom: 3rem;
/*  z-index: 25;*/
}

.containerGallery {
  bottom: 2rem !important;
}

.title {
  padding: 0rem 0rem 1rem 0rem;
  border-bottom: 2px white solid;
  margin: 0px;
  cursor: pointer;
}

.give {
  background: radial-gradient(#797676, black 75%);
  padding: 1em;
  width: 40%;
  margin: auto;
  display: block;
  font-family: inherit;
  border: none;
  font-weight: bolder;
  color: white;
  cursor: pointer;
}

@media only screen and (max-width: 1025px) {
  .canvasContainer {
    float: unset;
    width: 100%;
    height: 120vw;
  }

  .infoPanel {
    width: 100%;
    height: 100vh;
    padding-bottom: 0.5rem;
    padding-right: unset;
  }

  .infoPanelGallery {
    width: 100%;
    height: 100vh;
    padding-right: unset;
  }

  .blackContainer {
    width: 70%;
    height: unset;
    left: 0;
    right: 0;
  }
}

@media only screen and (orientation:landscape) {
  .infoPanelGallery {
    width: 50%;
    height: calc(100vh - 4em);
    padding-right: 0em;
    bottom: 0rem;
  }

  .containerGallery {
    bottom: 0.5rem !important;
  }

  .canvasContainer {
    width: 80%;
    height: 100vh;
    float: left;
  }
}