.error {
  position: absolute;
  top: 50vh;
  width: 50vw;
  text-align: center;
  right: 25vw;
  font-size: x-large;
  border: solid black 1px;
  border-radius: 50px;
  background: radial-gradient(pink, white 75%);
  color:  red;
  padding: 1em;
}