.lifeformContainer {
  width: 100vw;;
  height: 100vh;
}

@media only screen and (max-width: 1025px) {
    .lifeformContainer {
        display: flex;
        flex-direction: column;
    }
}