.modal {
  width: 50vw;
  position: fixed;
  top: 5em;
  z-index: 35;
  color: white;
  left: 20vw;
  background: white;
  padding: 2em;
  background-color: black;
  border-radius: 50px;
}

.close {
  float: right;
  width: 2rem;
}

.modal-contents {
  margin: 5em 1em 3em 1em;
}

.modal-contents input {
  padding: 1rem;
  border: none;
  border-radius: 25px;
  display: block;
  width: calc(100% - 2rem);
  padding: 1rem;
  text-indent: 2%;
}

.modal-contents span {
  display: block;
  margin-bottom: 3px;
  font-size: large;
  font-weight: bold;
}

.modal .submit {
  margin-top: 1rem;
  clear: both;
  background: radial-gradient(#797676, black 75%);
  border: 0 none;
  border-radius: 50px;
  transition: all 0.23s ease-in-out 0s;
  color: white;
  cursor: pointer;
  display: block;
  font-size: normal;
  font-weight: normal;
  margin: auto;
  padding: 1rem;
  text-align: center;
  text-decoration: none;
  font-weight: bold;
  font-family : inherit;
  vertical-align: top;
  white-space: nowrap;
  width: 50%;
}