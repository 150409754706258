.header {
  height: 5em;
  position: fixed;
  width: 100%;
  z-index: 1;
  top: 0;
}

.header button {
  float: right;
  font-family : inherit;
  padding: 1em;
  color:  black;
  border: solid black 1px;
  border-radius: 50px;
  margin: 1rem;
  border-radius: 50px;
  min-width: 10rem;
  background: transparent;
  font-weight: unset;
  cursor: pointer;
}

.headerMsg {
  float: right;
  font-family : inherit;
  padding: 1em;
  color:  black;
  border: solid black 1px;
  border-radius: 50px;
  margin: 1rem;
  min-width: 10rem;
  text-align: center;
  font-weight: unset;
}

.headerMintMsg {
  padding: 1em;
  position: absolute;
  color:  black;
  border: solid black 1px;
  margin-left: 1em;
  border-radius: 50px;
  z-index: 1;
  font-weight: unset;
  min-width: 10rem;
  text-align: center;
  cursor: pointer;
}

@media only screen and (max-width: 1025px) {
  .headerMsg {
    max-width: calc(100vw - 4rem);
    float: unset;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden; 
  }

  .headerMintMsg {
    max-width: calc(100vw - 4rem);
    float: unset;
    font-weight: unset;
    margin: unset;
    margin-left: 1rem;
    right:  unset;
  }
}

@media only screen and (max-width: 500px) {
  .header button {
    float: right;
    margin: 1em 1em 0em 1em;
    padding: 1em 2em;
    font-family : inherit;
  }

  .message {
    float: right;
    margin: 0em;
    padding: 0em 1em;
    color: #4c4c4d
  }

  .headerMsg {
    font-size: 0.8em;
    max-width: calc(100vw - 4rem);
    float: unset;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden; 
  }

  .headerMintMsg {
    font-size: 0.8em;
    max-width: calc(100vw - 4rem);
    float: unset;
    font-weight: unset;
    margin: unset;
    margin-left: 1rem;
    right:  unset;
  }
}
