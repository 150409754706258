.whiteout {
  height: 100vh;
  width: 100vw;
  background: white;
  opacity: 90%;
  position: fixed;
  z-index: 30;
  top: 0;
  left: 0;
}
