.ARcanvas {
  width: 100vw;
  height: 100vh;
/*  z-index: -10;*/
  position: absolute;
  top: 0;
  left: 0;
}

.hidden  {
  display: none;
}

.a_canvas {
  width: 100%;
}

body {
  width: 100% !important;
  height: 100% !important;
}