.iframe-1 {
  width: 33%;
  height: 100vh;
  border: none;
}

.about {
  padding: 1em;
  position: absolute;
  color:  black;
  border: solid black 1px;
  margin-left: 1em;
  border-radius: 50px;
  z-index: 1;
  font-weight: unset;
}

.homeText1 {
  background: black;
  border-radius: 50px;
  color: white;
  padding: 2em;
  width: 60%;
  font-weight: unset;
  position: absolute;
  z-index: 1;
  top:  60vh;
  left: calc(20% - 2rem);
}

.homeText2 {
  background: black;
  border-radius: 50px;
  color: white;
  padding: 2em;
  width: 60%;
  margin: auto;
  font-weight: unset;
  position: absolute;
  z-index: 1;
  top:  160vh;
  left: calc(20% - 2rem);
}

.homeText3 {
  background: black;
  border-radius: 50px;
  color: white;
  padding: 2em;
  width: 60%;
  margin: auto;
  font-weight: unset;
  position: absolute;
  z-index: 1;
  top: 260vh;
  left: calc(20% - 2rem);
}

.homeContainer1 {
  width: 100%;
  height: 100vh;
  margin: auto;
  position: absolute;
}

.homeContainer2 {
  width: 100%;
  height: 100vh;
  margin: auto;
  position: absolute;
  top: 100vh;
}

.homeContainer3 {
  width: 100%;
  height: 100vh;
  margin: auto;
  position: absolute;
  top:  200vh;
}

.no-lifeforms {
  position: absolute;
  top: 50%;
  width: 50vw;
  text-align: center;
  right: 25vw;
  font-size: x-large;
}

@media only screen and (max-width: 1200px) {
  .iframe-1 {
    width: 100vw;
    height: 160vw;
  }
}